import React, { Fragment, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import MetaTags from 'react-meta-tags'
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Content from '../sections/contact/Content'

// import dbData from '../data/db';


const Contact = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            <MetaTags>
                <title>{dbData.dbPrincipal.name} | Contact Us</title>
                <meta
                    name={dbData.dbSlogan[0].slogan}
                    content={dbData.dbHome[0].text}
                />
                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
            </MetaTags>
            <Header />
            <Breadcrumbs pagename='Contact' bgImage={dbData.gallery[25]} />
            <Content />
            <Footer />
        </Fragment>
    )
}

export default Contact;