import React, { useContext, Fragment } from 'react'
import { GlobalContext } from '../../data/GlobalContext';
import GalleryContent from '../gallery/GalleryContent';

const Content = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            {/* <h1 className='py-5 text-center text-capitalize'>Our Recent Projects</h1> */}
            {/* <GalleryContent
                galleryImages={
                    dbData.gallery.length > 0 ? dbData.gallery : dbData.stock
                }
            /> */}
            {
            dbData?.landings?.length > 0 ?
            dbData?.landings?.map((item, index) => {
                return (
                  <div className="pt-20 pb-32">
                    <div className="text-center py-5" key={index}>
                      <h2 className="text-center">{dbData?.labels?.general?.titleGallery}</h2>
                      <h2 className="capitalize">{item.name}</h2>
                    </div>
                    <GalleryContent galleryImages={item.gallery} />
                  </div>
                )
              })
              :
              <div className="pb-32">
                <div className="text-center py-5">
                  <h2 className="text-center pt-[100px]">{dbData?.labels?.general?.titleGallery}</h2>
                </div>
                <GalleryContent galleryImages={dbData?.gallery?.length > 0 ? dbData?.gallery : dbData?.stock} />
              </div>
          }
            <div className="py-5"></div>
        </Fragment>
    );
}


export default Content;