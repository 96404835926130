import React, { Fragment, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { GlobalContext } from '../../data/GlobalContext'

import classes from './servicesDetail.module.scss'
import * as FaIcons from 'react-icons/fa';


import MetaTags from 'react-meta-tags'
import Header from '../../layouts/Headertwo'
import Footer from '../../layouts/Footer'
import Breadcrumbs from '../../layouts/Breadcrumbs'
import { Container, Row, Col } from 'react-bootstrap'
// import ConuterBlack from '../../layouts/ConuterBlack'
import CounterWhite from '../../layouts/CounterWhite'
import FormContent from '../contact/FormContent'
import HeroImages from '../../layouts/HeroImages'



const ServicesDetail = () => {
    const dbData = useContext(GlobalContext);

    const { id } = useParams();

    return (
        <>
            {
                dbData.dbServices.filter((item) => item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id)
                    .map((item, index) => (
                        <Fragment key={index}>
                            <MetaTags>
                                <title>{dbData.dbPrincipal.name} | {item.name}</title>
                                <meta
                                    name={dbData.dbSlogan[0].slogan}
                                    content={dbData.dbHome[0].text}
                                />
                                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
                            </MetaTags>
                            <Header />
                            <Breadcrumbs
                                pagename={item.name}
                                bgImage={
                                    item.description.length > 1 ?
                                        item.description[0].img
                                        :
                                        dbData.gallery[24]
                                }
                            />
                            <Container className='py-5'>
                                <Row>
                                    <Col md={9} sm={12}>
                                        <div className={classes.serviceDetails}>
                                            <div className={classes.serviceDetails__img}>
                                                {
                                                    item.description.length > 1 ?
                                                        <img src={item.description[1].img} alt="service" />
                                                        :
                                                        <img src={item.description[0].img} alt="service" />
                                                }
                                            </div>
                                            <div className={classes.serviceDetails__content}>
                                                <h1>{item.name}</h1>
                                                {
                                                    item.description.map((item, index) => {
                                                        return (
                                                            <p key={index}>{item.text} <br /> <br /></p>
                                                        )
                                                    })
                                                }
                                                <Link to="/contact" className="main-btn text-capitalize">free estimate</Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <div className={classes.serviceDetails__sidebar}>
                                            <div className={classes.serviceDetails__item}>
                                                <h3>Our Services</h3>
                                                <ul>
                                                    {

                                                        dbData.dbServices.slice(0, 8).map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <Link to={`/${item.name.replace(" ", "-").toLowerCase()}`}>{item.name}</Link>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className={classes.serviceDetails__contact}>
                                                <h3>contact us</h3>
                                                <ul>
                                                    {
                                                        dbData.dbPrincipal.phones.map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <a href={`tel:+1${item.phone}`}>
                                                                        <i className="fa fa-phone"></i>
                                                                        {item.phone}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        dbData.dbPrincipal.emails.map((item, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <a href={`mailto:${item.email}`}>
                                                                        <i className="fa fa-envelope"></i>
                                                                        {item.email}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    <li>
                                                        <a href='#!'>
                                                            <i className="fa fa-map-marker"></i>
                                                            {dbData.dbPrincipal.location[0].address}
                                                        </a>

                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={classes.serviceDetails__redes}>
                                                <h3>follow us</h3>
                                                <ul>
                                                    {
                                                        dbData.dbSocialMedia.redes.map((item, index) => {
                                                            const Social =
                                                                FaIcons[
                                                                item.icon
                                                                ];
                                                            return (
                                                                <a key={index} href={item.url} target='_blank' rel='noopener noreferrer' className='mx-2'>
                                                                    <Social />
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="contact-form grey-bg">
                                    <div className="row no-gutters justify-content-center">
                                        <div className="col-10">
                                            <div className="section-title text-center mb-40">
                                                <h2 className="title pt-5">Don’t Hesited To Contact Us</h2>
                                            </div>
                                            <FormContent />
                                        </div>
                                    </div>
                                </div>
                            </Container>
                            <HeroImages bgImage={dbData.gallery[43]} />
                            <CounterWhite />
                            <Footer />
                        </Fragment>
                    ))
            }
        </>
    )
}


export default ServicesDetail;